import '../styles/MakeContact.css';
import emailjs from '@emailjs/browser';
import { useRef, useState, useEffect, useCallback } from 'react';

export function MakeContact() {
    const form = useRef(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [emailMatch, setEmailMatch] = useState(true);
    const [isSent, setIsSent] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state to track form submission

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Set to true when form is being submitted

        emailjs.sendForm('service_bf_website', 'template_bf_website', form.current, 'v4emaAYVER_f9OQ6b')
            .then((result) => {
                console.log(result.text);
                setIsSent(true);
                setIsSubmitting(false); // Set back to false when form submission is complete
            }, (error) => {
                console.log(error.text);
                setIsSubmitting(false); // Set back to false if there's an error during submission
            });
    };

    const checkFormValidity = useCallback(() => {
        const inputs = form.current.querySelectorAll('input[name], textarea[name]');
        const isValid = Array.from(inputs).every((input) => input.value.trim() !== '');
    
        const email = form.current.querySelector('input[name="user_email"]').value;
        const confirmEmail = form.current.querySelector('input[name="confirm_email"]').value;
    
        // Compare emails directly here to avoid dependency issues
        const emailMatches = email === confirmEmail;
    
        // Update both states in one function to avoid async dependency problems
        setEmailMatch(emailMatches);
        setIsFormValid(isValid && emailMatches);
    }, []); // Empty dependency array ensures stability
    
    const handleInputChange = () => {
        checkFormValidity();
    };
    
    useEffect(() => {
        // Call the validation function on mount or dependency updates
        checkFormValidity();
    }, [checkFormValidity]);
    

    return (
        <>
            <div className='send_container'>
                <form ref={form} onSubmit={sendEmail} className='sendEmail_box'>
                    <label className='label'>Name</label>
                    <input type="text" name="user_name" className='input' onChange={handleInputChange} />
                    <label className='label'>Company</label>
                    <input type="text" name="user_company" className='input' onChange={handleInputChange} />
                    <label className='label'>Email</label>
                    <input type="email" name="user_email" className='input' onChange={handleInputChange} />
                    <label className='label'>Confirm Email</label>
                    <input type="email" name="confirm_email" className='input' onChange={handleInputChange} />
                    {!emailMatch && <p className="error">Emails do not match</p>}
                    <label className='label'>Message</label>
                    <textarea name="message" className='text_area' onChange={handleInputChange} />
                    <div className='send_button'>
                        <button type="submit" className={`send ${isFormValid ? 'active' : 'inactive'} ${isSent ? 'sent' : ''}`} disabled={!isFormValid || isSubmitting}>
                            {isSubmitting ? '...' : (isSent ? 'Sent' : 'Send')}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}
