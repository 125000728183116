import tractor from '../images/tractor.webp';
import truck from '../images/camion.webp';

import caterpillar from '../images/tractors/cat.jpeg';
import bobcat from '../images/tractors/bobcat.jpg';
import case_tractors from '../images/tractors/case.jpg';
import john_deere from '../images/tractors/john deere.webp';
import kobelco from '../images/tractors/kobelco.webp';
import komatsu from '../images/tractors/komatsu.jpg';
import new_holland from '../images/tractors/new holland.webp';
import hitachi from '../images/tractors/hitachi.jpg';

import mercedes from '../images/trucks/mercedes.jpg';
import hyunday from '../images/trucks/hyundai.jpg';
import volvo from '../images/trucks/volvo.webp';
import ford from '../images/trucks/ford.webp';

import laser_closed from '../images/laser/laser marker.png';
import laser_open from '../images/laser/laser marker open.jpg';
import laser_scope from '../images/laser/laser of the marker.jpg';
import laser_inside from '../images/laser/inside marker lateral.jpg'

import welder from '../images/laser/laser welder.jpeg';
import welder_lateral from '../images/laser/laser welder lateral.jpeg';
import welder_open from '../images/laser/laser welder open.jpeg';

export const products=[
    {
        description: 'Spare parts for tractors and heavy duty machines',
        mainSubject: 'heavy machinery',
        subjects:[
            {
                subject: 'tractors',
                desc: [
                    {
                    text: "Tailor-made spare parts and gears for tractors and heavy machinery",
                    cName: "padding_right",
                    },
                    {
                    text: "Wide choice of brands and manufacturers",
                    cName: "padding_left",
                    },
                    {
                    text: "International deliveries for all products",
                    cName: "padding_right",
                    }
                ],
                image_alt: tractor,
                brands: [caterpillar, komatsu, case_tractors, john_deere, hitachi, bobcat, new_holland, kobelco],
            },
            
            {
                subject: 'trucks',
                desc: [{
                    text: "Tailor-made spare parts and gears for commercial vehicles",
                    cName: "padding_right",
                },{
                    text: "Transmissions, axles, engine gears",
                    cName: "padding_left",
                },{
                    text: "International deliveries of all products",
                    cName: "padding_right",
                }],
                image_alt: truck,
                brands: [mercedes, volvo, hyunday, ford],
            }
        ]
    },

    {
        description: 'High precision laser machines',
        mainSubject: 'industrial machines',
        subjects:[
            {
                subject: 'laser engraver',
                desc:[{
                    text: "Metal laser engraver",
                    cName: "padding_right"
                },
                {
                    text: "Many dimensions and structures of the laser cabin",
                    cName: "padding_left"
                },{
                    text: "Engrave your logo and symbols of any choice",
                    cName: "padding_right"
                }],
                image_alt: "laser",
                brands: [laser_closed, laser_open, laser_scope, laser_inside],
            },

            {
                subject: 'laser welder',
                desc:[{
                    text: "Metal laser welder",
                    cName: "padding_right"
                },
                {
                    text: "Easy to oparate and maintain",
                    cName: "padding_left"
                },{
                    text: "Weld with adjustble power",
                    cName: "padding_right"
                }],
                image_alt: "laser",
                brands: [welder, welder_lateral, welder_open],
            },
    ]
    }

]