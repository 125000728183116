/*import { ProductsDisplay } from '../components/ProductsDisplay';*/
import { ProductsHome } from '../components/ProductsHome';
import '../styles/Products.css'

import { Footer } from '../components/Footer';

export function Products() {
    return(
        <>
        <div className="page_body">
            <div className='above_margin'>
                <ProductsHome />
            </div>
            <Footer />
        </div>
        </>
    );
}