import '../styles/NotFound.css';
import not_found_icon from '../images/not found.png';
import React from 'react';

export function NotFound (){
    return(
        <>
        <div className="page_body">
            <div className="not_found_container">
                <div className='not_found_icon'><img className='not_found_img' src={not_found_icon} alt='gear'/></div>
                <div className='not_found_sentence'>Page Not Found</div>
            </div>
        </div>
        </>
    );
}