import contacts from '../list/contacts.json';

export function References() {
    return(
        <>
        {contacts.map((item, index) => {
            return(
                <div key={index} className='reference'>
                    <div className='reference_list'>
                        <div className='ref company_name'>{item.company_name}</div>
                        <div className='ref phone'>phone: {item.phone}</div>
                        <div className='ref'>{item.email}</div>
                        <div className='ref'><div className='cuntry'>{item.country}</div></div>
                        <div className='ref city'>{item.city}</div>
                        <div className='ref address'>{item.address}</div>
                    </div>
                </div>
            );
        })}
        </>
    );
}