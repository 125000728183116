import contacts from '../list/contacts.json';
import mediaData from '../list/media.json';
import { Link } from 'react-router-dom';
import '../styles/Footer.css'

import bf_logo from '../images/logo bf clean.png';

export function Footer() {
    return(
        <>
        <div className='footer_container'>
            <div className='info_container'>
                <div className='footer_element'>
                    <div className='homepage_link_box'><Link to={'/'} className='homepage_link'><img src={bf_logo} alt='homepage' className='link_to'/></Link></div>
                </div>
                <div className='footer_element'>
                {contacts.map((item, index) => {
                    return(
                        <div key={index} className='foot_reference'>
                            <div className='foot_reference_list'>
                                <div className='foot company_name'>{item.company_name}</div>
                                <div className='foot phone'>phone: {item.phone}</div>
                                <div className='foot'>{item.email}</div>
                                <div className='foot cuntry'>{item.country}</div>
                                <div className='foot city'>{item.city}</div>
                                <div className='foot address'>{item.address}</div>
                            </div>
                        </div>
                    );
                })}
                </div>
                <div className='footer_element'>
                {mediaData.media.map((mediaItem,mediaIndex) => {
                    return(
                    <a key={mediaIndex} href={mediaItem.link} target="_blank" rel="noopener noreferrer" className='media'>
                        <img key={mediaIndex} classname={mediaItem.cName} src={mediaItem.image} alt='google maps'/>
                    </a>    
                    );
                })}</div>
            </div>
            <div className='copyright'>© Copyright  2024 BF Parts. All rights reserved</div>
        </div>
        </>
    );

}
