import '../styles/IndustrialMachines.css';

import { Footer } from '../components/Footer.js';
import { products } from '../list/products.js';

export function IndustrialMachines() {
    return(
        <>
        <div className="page_body">
            <div className='machine_presentation'>
                {products.map((item,index) => { 

                    if(item.mainSubject === 'industrial machines'){
                        
                        return(
                            <div key={index} className='presentation_body'>
                                {item.subjects.map((subItem,subIndex) => {

                                    return(
                                        <div key={subIndex} className='single_data'>
                                            <div className='name_title'>{subItem.subject}</div>
                                            <div className='desc_box_style'>{subItem.desc.map((descItem,descIndex) => {
                                                return(<div key={descIndex} className='desc_style'>{descItem.text}</div>);
                                            })}</div>
                                            <div>{subItem.brands}</div>
                                        </div>
                                    );

                                })}
                            </div>
                        );

                    } else {
                        return(<div></div>);
                    }
                })}
            </div>
            <Footer />
        </div>
        </>
    );
}