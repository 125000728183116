import { MakeContact } from '../components/MakeContact';
import { Footer } from '../components/Footer';
import { References } from '../components/References';
import '../styles/Contacts.css'

export function Contacts(){
    return(
        <>
        <div className="page_body">
            <div className='title'>Contacts and info</div>
            <div className='contact_box'>
                <References />
                <MakeContact />
            </div>
            <Footer />
        </div>
        </>
    );
}