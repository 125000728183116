import React, { useState, useEffect, useRef } from 'react';
import '../styles/Header.css';
import { Link } from 'react-router-dom';
import bfLogo from '../images/logo bf clean.png';

//import list of header menu
import menuData from '../list/headerMenu.json';

export function Header() {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [showSidebar, setShowSidebar] = useState(false);
    const sidebarRef = useRef(null);
    const iconRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                sidebarRef.current && 
                !sidebarRef.current.contains(event.target) &&
                iconRef.current && 
                !iconRef.current.contains(event.target)
            ) {
                setShowSidebar(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (showSidebar) {
                setShowSidebar(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showSidebar]);

    // Open and close function for the sidebar icon
    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };


    function menu() {
        if(screenWidth >= 500){
            return(
                <div className='menu'>
                    {menuData.map((menuItem, menuIndex) =>{
                        return(
                            <div key={menuIndex} className={menuItem.cNameDiv}>
                            <Link to={menuItem.linkTo} className={menuItem.cNameLink}>{menuItem.text}</Link>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return(
                <div ref={sidebarRef} className={showSidebar ? 'sidebar' : 'invisible'}>
                    <div className='mid_sidebar_box'>
                        {menuData.map((menuItem, menuIndex) =>{
                            return(
                                <div key={menuIndex} className={menuItem.cNameDiv}>
                                <Link to={menuItem.linkTo} className={menuItem.cNameLink}>{menuItem.text}</Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    }

    return(
        <>
            <div className="head_container">
                <div className="logo_box">
                    <Link to={'/'} className='logo_box_link'><img className='logo' src={bfLogo} alt='BF'/><div className='logo_name'>B.F. s.r.l.<div className='date_found'>since 1969</div></div></Link>
                </div>
                <div className='sidebar_icon'>
                    <div ref={iconRef} onClick={toggleSidebar} className='m_icon'>☰</div>
                </div>
                {menu()}
            </div>
        </>
    );
    
}
