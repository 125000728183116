import { useEffect} from 'react';
import { photoRoll } from '../list/photoRoll';
import { Link } from 'react-router-dom';
import '../styles/PhotoDisplay.css'

export function PhotoDisplay() {

    useEffect(() => {
        function checkVisibility() {
          var elements = document.querySelectorAll('.fade-in');
          for (var i = 0; i < elements.length; i++) {
            var windowHeight = window.innerHeight;
            var scrollY = window.scrollY || window.pageYOffset;
            var elementOffsetTop = elements[i].getBoundingClientRect().top + scrollY;
    
            if (scrollY + windowHeight > elementOffsetTop) {
              elements[i].classList.add('show');
            }
          }
        }
    
        window.addEventListener('scroll', checkVisibility);
        window.addEventListener('resize', checkVisibility);
    
        checkVisibility();

        return () => {
          window.removeEventListener('scroll', checkVisibility);
          window.removeEventListener('resize', checkVisibility);
        };
    }, []);
      

    return(
        <>
        <div className="photo_roller">
            {photoRoll.map((item,index) => {
                let button 
                if(item.type === 'product'){
                    button = <Link to={'/products'} className='button'>Discover</Link>
                }
                if (index %2 === 0){
                    return(
                        <div key={index} className='roller fade-in right'>
                            <div className='photo_desc'>
                                <div className='desc_text'>{item.text}</div>
                                {button}
                            </div>
                            <img className='display' src={item.img} alt='machines'/>
                        </div>
                    )}else{
                        return(
                            <div key={index} className='roller fade-in left'>
                                <img className='display' src={item.img} alt='machines'/>
                                <div className='photo_desc'>
                                    <div className='desc_text'>{item.text}</div>
                                    {button}
                                </div>
                            </div>
                        )
                    }
            })}
        </div>
        </>
    );
}