import '../styles/Headliner.css'

export function Headliner ({title}){
    return(
        <>
        <div className="box">
            <div className="intro">{title}</div>
        </div>
        </>
    );
}