import '../styles/Home.css'
import { Headliner } from "../components/Headliner";
import { PhotoDisplay } from '../components/PhotoDisplay';
import { Footer } from '../components/Footer';

export function Home() {

    const dynamicTitle = "Parts for tractors, heavy machineries and trucks";
    return(
        <>
        <div className="page_body">
            <div className='headliner'><Headliner title={dynamicTitle}/></div>
            <div className='photo_display'><PhotoDisplay/></div>
            <Footer />
        </div>
        </>
    );
}