import first_bf from '../images/frnacesco b first address.png';
import parts from '../images/tractors/ingranaggio.png';
import laser from '../images/laser/laser marker open.jpg'
import shipments from '../images/container ship.png';


export const story = [
    {
        className: 'story_box_top',
        text:(<div className='center'><div className='margin'><span className='bolder'>B.F was founded in 1969 by Biagi Francesco whose initials give the company its name.</span>
            B.F. was founded in the city of Bologna, Italy, and together with the city of Modena, is the city home of tractors and engineering companies.
            The company site changed once from the foundation, and moved from near the city center, to more outside the city in the town of Zola Perdosa.</div></div>),
        cn_text: 'text_sb_bottom',
        cn_img: 'img_sb_top',
        story_img: first_bf,
    },
    {
        className: 'story_box_right',
        text:(<div className='center'><div className='margin'><span className='bolder'>We are focused in producing spare parts for heavy machinery.</span>
        Our parts extends for many major havy machinery brands as Caterpillar, John Deere, Komatsu, Case, Hitachi, New Holland, BobCat.
        About transportation we provide the manufacturing of parts for the major trucks brands: Volvo, Mercedes, Hyunday.</div></div>),
        cn_text: 'text_sb_left',
        cn_img: 'img_sb_right',
        story_img: parts,
    },
    {
        className: 'story_box_top',
        text:(<div className='center'><div className='margin'><span className='bolder'>Laser markers and laser welders are used and sold by us</span>
        We use laser marking machines to engrave clean and high resolution codes on our spare parts, and with the laser welder we have an efficent and precise machine for the purpose.
        We provide our costumers with the same efficient equipment.</div></div>),
        cn_text: 'text_sb_bottom',
        cn_img: 'img_sb_top',
        story_img: laser,
    },
    {
        className: 'story_box_left',
        text:(<div className='center'><div className='margin'><span className='bolder'>Shipments all over the world</span>
        We ship to our costumers all over the world, from U.S.A to Asia and middle East.</div></div>),
        cn_text: 'text_sb_right',
        cn_img: 'img_sb_left',
        story_img: shipments,
    }
]